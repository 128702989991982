import './App.css';
import { useState, useEffect, useCallback } from "react";
import data from "./data";
import ReactMarkdown from 'react-markdown';
import { BiMessageDetail } from "react-icons/bi";
import { LuClipboardSignature } from "react-icons/lu";
import { LuBookOpen } from "react-icons/lu";
import { LuCoffee } from "react-icons/lu";
import { TbSunMoon } from "react-icons/tb";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function debounce(fn, wait, immediate) {
  let timeout;

  return (...args) => {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) fn.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      fn.apply(context, args);
    }
  };
}

function filterData(data, filter = "", typeFilter = "all") {
  if (typeFilter === "all" && filter.length) return data;

  const typeFilteredItems = {};
  typeFilteredItems.items = typeFilter !== "all" ? data.items.filter(item => item.type === typeFilter) : data.items;

  if (filter.length) return typeFilteredItems;

  const filtered = {};
  filtered.items = filter.length ? typeFilteredItems.items.filter(item => {
    return item.title.toLowerCase().includes(filter.toLowerCase()) || item.content.toLowerCase().includes(filter.toLowerCase());
  }) : typeFilteredItems.items;

  return filtered;
}

function sanitizeTitle(title) {
  return title.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
}

function CopyButton({ valueToCopy }) {
  const [copied, setCopied] = useState(false);

  function handleClick(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <button
      className={`copy-button ${copied ? 'copied' : ''}`}
      onClick={() => handleClick(valueToCopy)}
    >
      {copied ? 'Copied!' : 'Copy'}
    </button>
  );
}

function App() {
  const [filter, setFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("all");
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [theme, setTheme] = useState("dark"); // Initialize theme state

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' from the hash
    if (hash) {
      const item = data.items.find(item => sanitizeTitle(item.title) === hash);
      if (item) {
        setExpandedCategory(item.category);
        setExpandedItems([sanitizeTitle(item.title)]);
      } else {
        setExpandedCategory(hash);
      }
    }
  }, []);

  function handleFilter(event) {
    debounce(setFilter(event.target.value), 300);
  }

  const handleExpandItem = useCallback((item) => {
    const itemId = sanitizeTitle(item.title);
    const newExpanded = expandedItems.includes(itemId) ? expandedItems.filter(i => i !== itemId) : [...expandedItems, itemId];
    setExpandedItems(newExpanded);
    window.history.pushState(null, '', `#${itemId}`);
  }, [expandedItems]);

  const handleCategoryClick = useCallback((category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
    setExpandedItems([]); // Close all items when a new category is clicked
    window.history.pushState(null, '', `#${sanitizeTitle(category)}`);
  }, [expandedCategory]);

  function clearFilters() {
    setFilter("");
    setTypeFilter("all");
    setExpandedItems([]); // Close all items
    setExpandedCategory(null); // Close all categories
    window.history.pushState(null, '', window.location.pathname); // Clear the hash in the URL
  }

  function toggleTheme() {
    const newTheme = theme === "dark" ? "light" : "dark";
    console.log("Toggling theme to:", newTheme);
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme); // Apply the new theme to the document
    console.log("Current theme attribute:", document.documentElement.getAttribute('data-theme'));
  }

  const filtered = filter.length || typeFilter !== "all" ? filterData(data, filter, typeFilter) : data;

  // Group items by category and sort within each category
  const groupedItems = filtered.items.reduce((acc, item) => {
    const category = item.category;
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});

  Object.keys(groupedItems).forEach(category => {
    groupedItems[category].sort((a, b) => a.title.localeCompare(b.title));
  });

  const totalScripts = data.items.length;

  return (
    <div className="App">
      <div className="header">
        <h6>Proudly serving {totalScripts} scripts</h6>
      </div>
      <div className={"inputFilter"}>
        <span align="center">
          <input type="text" onChange={handleFilter} value={filter} placeholder="Search"/>
          <button onClick={clearFilters} style={{ background: 'none', border: 'none', color: 'var(--text-color)', cursor: 'pointer', transition: 'background-color 0.5s, color 0.5s, border-radius 0.5s', padding: '3px 6px', marginLeft: '1px' }} onMouseOver={(e) => { e.target.style.backgroundColor = 'var(--background-hover)'; e.target.style.color = 'var(--button4-hover)'; e.target.style.borderRadius = '8px'; }} onMouseOut={(e) => { e.target.style.backgroundColor = 'transparent'; e.target.style.color = 'var(--text-color)'; e.target.style.borderRadius = '0'; }}>
            Reset
          </button>
        </span>
        <span align="center">&nbsp; &nbsp;
          <a className="button4" href="https://github.com/tteck/Proxmox/blob/main/CHANGELOG.md" target="_blank" rel="noopener noreferrer" data-umami-event="Changelog"><LuClipboardSignature className="icon-container"/> Changelog</a>
          <a className="button4" href="https://github.com/tteck/Proxmox/discussions" target="_blank" rel="noopener noreferrer" data-umami-event="Discussion"><BiMessageDetail className="icon-container"/> Discussions</a>
          <a className="button4" href="https://github.com/tteck/Proxmox/blob/main/USER_SUBMITTED_GUIDES.md" target="_blank" rel="noopener noreferrer" data-umami-event="Guides"><LuBookOpen className="icon-container"/> Guides</a>
          <a className="button4" href="https://ko-fi.com/proxmoxhelperscripts" target="_blank" rel="noopener noreferrer" data-umami-event="Support this Project"><LuCoffee className="icon-container"/> Support this Project</a>
          <button className="button4" onClick={toggleTheme}style={{ padding: '5px 10px'}}><TbSunMoon className="icon-container"/> Theme</button>
        </span>
      </div>
      {Object.keys(groupedItems).length === 0 && <div>No items match your criteria <button onClick={clearFilters}>Clear Filters</button></div>}
      {Object.keys(groupedItems).map((category) => (
        <div key={sanitizeTitle(category)}>
          <div onClick={() => handleCategoryClick(category)} className={"itemCategory"}>
            {category}
          </div>
          {groupedItems[category].map((item) => {
            const itemId = sanitizeTitle(item.title);
            return (
              <div className={"App-items"} key={itemId}>
                {((filter && item.title.toLowerCase().includes(filter.toLowerCase())) || expandedItems.includes(itemId) || expandedCategory === item.category) && (
                  <span onClick={() => handleExpandItem(item)} className={"itemTitle"}>
                    {item.title}
                  </span>
                )}
                {((filter && item.title.toLowerCase().includes(filter.toLowerCase())) || expandedItems.includes(itemId)) && (
                  <div className="item">
                    <ReactMarkdown
                      children={item.content}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || '');
                          return !inline && match ? (
                            <>
                              <CopyButton valueToCopy={String(children)} />
                              <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                style={coldarkDark}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                              />
                            </>
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default App;
